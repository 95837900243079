import { initializeApp } from 'firebase/app';
import { getAnalytics, isSupported } from "firebase/analytics";
import firebase from "firebase/compat";
// @ts-ignore
// import { gtag, install } from 'ga-gtag';

// Firebase configuration

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_ANALYTICS_TRACKING_ID,
};

const app = initializeApp(firebaseConfig);
// install(process.env.REACT_APP_FIREBASE_ANALYTICS_TRACKING_ID);

const analyticsMock = {
  logEvent: () => {},
  setCurrentScreen: () => {},
  setUserId: () => {},
}

let analytics

isSupported().then((result)=>{
  if(result){
    analytics = getAnalytics(app)
  }
  analytics = analyticsMock
})

export default {app, analytics}
